import React from "react";
import Artykul from "../components/artykul";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/Dane-osobowe-w-CRM-Co-warto-wiedziec.png";

export const query = graphql`
  query {
    zdjecie1: file(
      relativePath: {
        eq: "bezpieczenstwo-certyfikat-SSL-CRM-a-rodo-Rodo-w-CRM-CRM-i-Rodo-300x163.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(
      relativePath: {
        eq: "Rodo-w-CRM-CRM-zgody-z-Rodo-Zadbaj-o-bezpieczenstwo-danych-osobowych-z-systemem-CRM-Quick-CRM-300x86.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    zdjecie5: file(relativePath: { eq: "system-crm-Baner-testuj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const DaneOsobowewCRM = ({ data }) => {
  return (
    <Artykul
      title="Dane osobowe w CRM. Co musisz wiedzieć?"
      articleImage={obrazekArtykulu}
      keywords={["dane osobowe w crm"]}
      articleImageAlt="Dane osobowe w CRM. Co warto wiedzieć."
      metaTitle="Dane osobowe w CRM. Co musisz wiedzieć?"
      metaDescription="✅ Dane osobowe w CRM • Co warto wiedzieć na temat przetwarzania danych osobowych w CRM? • QuickCRM, prosty system CRM dla dynamicznych zespołów."
    >
      <p>
        W związku z przepisami, które wprowadza RODO, od 25 maja 2018 r. dane
        osobowe mogą być gromadzone i przetwarzane tylko w prawnie określonych
        celach. Jak przygotować firmę i swój system CRM na wymogi Rozporządzenia
        o Ochronie Danych Osobowych? Jak należy chronić dane osobowe w CRM?
      </p>
      <h2>Czy RODO mnie dotyczy?</h2>

      <p>
        Unijne rozporządzenie dotyczy wszystkich podmiotów, które przetwarzają
        dane osobowe. Takim podmiotem może być firma, instytucja lub osoba
        fizyczna. Administrator danych osobowych to podmiot, który decyduje o
        celach i sposobie przetwarzania danych. W świetle tego można jasno
        powiedzieć, że RODO dotyczy każdego przedsiębiorstwa, które gromadzi
        dane swoich kontrahentów i współpracowników.
      </p>
      <h3>Dane osobowe w CRM – wsparcie systemu</h3>
      <p>
        System CRM, który jest przystosowany do RODO powinien wspierać Twoją
        firmę na dwóch płaszczyznach. Z jednej strony wspiera sprawną pracę na
        co dzień, a z drugiej dba o bezpieczeństwo wszystkich zasobów.
      </p>
      <br />
      <strong>Gwarancja bezpieczeństwa</strong>

      <p>
        <Img
          style={{
            width: "0 auto",
            maxWidth: 300
          }}
          title="Bezpieczeństwo. Certyfikat SSL. CRM a rodo. Rodo w CRM. CRM i Rodo."
          alt="Bezpieczeństwo. Certyfikat SSL. Dane osobowe w CRM. Rodo w CRM. CRM i Rodo."
          fluid={data.zdjecie1.childImageSharp.fluid}
        />
        Dane osobowe w CRM muszą być szczególnie chronione. Dlatego system
        QuickCRM zapewnia wysoki stopień bezpieczeństwa. Dzięki zastosowaniu
        protokołu SSL i szyfrowania danych, oprogramowanie chroni przed
        nieautoryzowanym dostępem. Jako że bezpieczeństwo danych osobowych to
        jedno z głównych założeń RODO, poświęcamy mu bardzo dużo uwagi.
      </p>
      <h3>Zarządzanie uprawnieniami</h3>

      <p>
        Twoi pracownicy na co dzień zajmują się przetwarzaniem danych osobowych.
        Ale czy każdy z nich powinien mieć taki sam dostęp do zasobów? To zależy
        od Ciebie. System CRM pozwala Ci na kontrolowanie tego, co widzą
        pracownicy w systemie. Dzięki uprawnieniom dostępu, możesz stworzyć
        różne profile użytkowników, dla których dostępne będą różne części
        systemu.
      </p>
      <h3>Rejestrowanie zmian</h3>
      <p>
        QuickCRM zapisuje każdą edycję karty klienta wraz z datą, godziną i
        osobą, która zmian dokonała. Co więcej, dostępne są też informacje nt.
        założenia karty kontrahenta oraz historia zmian wszystkich atrybutów,
        które są do niej przypisane.
      </p>
      <br />
      <strong>Możliwość usunięcia danych</strong>
      <p>
        Zgodnie z RODO, każdy człowiek ma prawo zwrócić się do administratora,
        aby ten usunął jego dane osobowe w całości i ma on obowiązek to żądanie
        zrealizować. Prawo do bycia zapomnianym – pod taką nazwą kryje się ów
        przepis, możesz zrealizować w systemie CRM. Administrator może na
        wniosek kontrahenta wykreślić go z listy mailingowej, z bazy klientów i
        z każdego innego miejsca w systemie.
      </p>
      <br />
      <strong>Bezpieczne logowanie</strong>
      <p>
        Dane osobowe w CRM są pod szczególną ochroną, dlatego QuickCRM zapewnia,
        że dostęp do nich będą mieli tylko upoważnieni użytkownicy. Dlatego
        wspiera bezpieczeństwo logowania dzięki systemowi zaawansowanego
        uwierzytelniania. System może wymuszać utworzenie silnego hasła oraz
        automatycznie prosić użytkownika o jego zmianę. Przy kilkukrotnym
        podaniu błędnego hasła, system zablokuje dostęp do danych. Administrator
        może natomiast przeglądać historię logowania do systemu oraz wysyłać
        użytkownikom komunikat z prośbą o zmianę hasła. Rodo w CRM. CRM zgody z
        Rodo. Zadbaj o bezpieczeństwo danych osobowych z systemem CRM - Quick
        CRM
      </p>
      <br />
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto",
          maxWidth: 300
        }}
        title="Rodo w CRM. CRM zgody z Rodo. Zadbaj o bezpieczeństwo danych osobowych z systemem CRM - Quick CRM"
        alt="Rodo w CRM. Dane osobowe w CRM. Zadbaj o bezpieczeństwo danych osobowych z systemem CRM - Quick CRM"
        fluid={data.zdjecie2.childImageSharp.fluid}
      />
      <strong>Swoboda w zbieraniu danych</strong>
      <p>
        Zgodnie z RODO dane, które przetwarza Twoja firma muszą być gromadzone w
        ściśle określonym celu. Nie należy więc umieszczać tam danych, które nie
        są konieczne do sprawnej obsługi klientów. System QuickCRM nie wymusza
        tego, jakie dane znajdą się w karcie klienta oraz nie wymaga
        wprowadzania konkretnych informacji. To Ty możesz zdecydować, jakie dane
        gromadzisz. System CRM umożliwia wprowadzanie wielu rodzajów danych, to
        administrator decyduje, jakie cechy kontrahenta będą dostępne w
        systemie.
      </p>
      <h3>RODO w CRM – prawo do bycia informowanym</h3>
      <p>
        Rozporządzenie nadaje konsumentom prawo do bycia informowanym oraz prawo
        do dostępu do danych osobowych. To oznacza, że administrator ma
        obowiązek udostępnienia danych każdego klienta na jego wniosek. System
        CRM umożliwia wgląd i edycję danych upoważnionym użytkownikom. Jeśli
        zgłosi się do Ciebie klient z wnioskiem o udostępnienie mu jego danych,
        w kilka chwil możesz mu je przesłać.
      </p>
      <Link to="/demo-crm/">
        <Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="Testuj System CRM"
          alt="Testuj system CRM. Dane osobowe w CRM"
          fluid={data.zdjecie5.childImageSharp.fluid}
        />
      </Link>
    </Artykul>
  );
};

export default DaneOsobowewCRM;
